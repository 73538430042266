import React from 'react';
import PageLayout from '../../../shared/PageLayout';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Badge from '../../../page-components/homePage/Articles/Badge';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import axios, { apiBaseUrl } from '../../../shared/Helpers/axios.js';

const CategoryArticles = ({
  location,
  category: currentCategory,
  serverData,
}) => {
  const {
    categories,
    articles,

    layoutData: { contact },
  } = serverData;
  const links = location.pathname
    .slice(1)
    .split('/')
    .filter((path) => path.length);

  return (
    <PageLayout categories={categories} contact={contact}>
      <div className="w-90-lg w-full mx-auto">
        <div className="container mx-auto px-6 py-12">
          <div className="flex items-center gap-2 gap-4-lg">
            <Link to="/" className="text-base text-sm text-gray-300">
              Accueil
            </Link>
            {links.map((link, index) => (
              <div className="flex gap-2 gap-4-lg" key={link}>
                <span className="text-base text-sm text-gray-300">/</span>
                <Link
                  key={link}
                  to={`${index > 0 ? '/' + links[index - 1] : ''}/${link}`}
                  className="text-base text-sm text-gray-300"
                >
                  {link[0].toUpperCase() + link.slice(1)}
                </Link>
              </div>
            ))}
          </div>
          <h1 className="font-lba text-white text-5xl text-center -translate-y-50-lg -z-10 relative mt-8 mt-0-lg">
            Articles
          </h1>

          <div className="flex items-center flex-wrap justify-center gap-8-lg mt-8-lg mt-3">
            <Link
              to="/articles"
              className="text-base text-gray-300 uppercase me-4 me-0-lg"
            >
              Tous les articles
            </Link>
            {categories?.map((category) => {
              const active =
                category?.attributes?.slug === currentCategory
                  ? 'text-white'
                  : 'text-gray-300';
              return (
                <Link
                  key={category?.attributes?.slug}
                  to={`/articles/${category?.attributes?.slug ?? ''}`}
                  className={`text-base text-white uppercase me-4 me-0-lg ${active}`}
                >
                  {category.attributes?.name ?? ''}
                </Link>
              );
            })}
          </div>

          <div className="grid grid-cols-3-lg grid-cols-2-md gap-7 mt-6 place-items-center">
            {articles?.map((article) => {
              const createdAt = article?.attributes?.createdAt;
              const date = createdAt
                ? new Date(article.attributes?.createdAt).toLocaleDateString()
                : '';
              const formattedDate = date.split('/').join(' / ');
              return (
                <div
                  key={article?.attributes?.title}
                  className="flex flex-col relative max-w-400-px max-w-full-lg w-full"
                >
                  <img
                    src={`${apiBaseUrl}${article?.attributes?.cover?.data?.attributes?.url}`}
                    alt={article.attributes.title}
                    className="max-w-full w-full mb-3 h-200-px object-cover"
                  />
                  <div className="flex items-center justify-between w-full mb-2">
                    <Badge
                      bgColor={
                        article.attributes?.category?.data?.attributes?.color
                      }
                    >
                      {article?.attributes?.category?.data?.attributes?.name}
                    </Badge>
                    <span className="text-base-lg text-sm text-white">
                      {formattedDate}
                    </span>
                  </div>
                  <h3 className="font-bold text-2xl-lg text-xl text-white line-clamp-2 mb-3">
                    {article?.attributes?.title}
                  </h3>
                  <p className="text-white text-base-lg text-sm line-clamp-4">
                    <BlocksRenderer content={article?.attributes?.text} />
                  </p>
                  <Link
                    to={`/articles/${article?.attributes?.category?.data?.attributes?.slug ?? ''}/${article?.attributes?.slug}`}
                    className="opacity-0 full-w-link absolute top-0 left-0 h-full w-full"
                  >
                    {article?.attributes?.title}
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export async function getServerData({ params }) {
  const currentCategory = params.category;
  try {
    const { data } = await axios.get('/api/home-page', {
      params: {
        populate: {
          contact: {
            fields: [
              'instagram',
              'facebook',
              'youtube',
              'twitter',
              'linkedin',
              'contactEmail',
            ],
          },
        },
      },
    });
    const { data: categoryData } = await axios.get('/api/categories', {
      params: {
        fields: ['name', 'slug'],
      },
    });

    const { data: articleData } = await axios.get('/api/articles', {
      params: {
        pagination: {
          pageSize: 100000,
        },
        sort: 'createdAt:desc',
        populate: {
          category: {
            fields: ['name', 'slug', 'color'],
          },
          cover: {
            fields: ['url'],
          },
        },
        filters: {
          category: {
            slug: { $eq: currentCategory },
          },
        },
      },
    });

    return {
      props: {
        categories: categoryData.data,
        articles: articleData.data,
        layoutData: {
          ...data.data.attributes,
        },
      },
    };
  } catch (error) {
    console.log(error);
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}

CategoryArticles.propTypes = {
  serverData: PropTypes.object,
  location: PropTypes.object,
  category: PropTypes.string,
};

export default CategoryArticles;
